import React from "react"
import {useSelector, useDispatch} from "react-redux"
import { useCookies } from "react-cookie"
import SEO from "../components/seo/seo"
import Layout from "../components/layout/layout"
import AppBar from "../components/appBar/appBar"
import MainSection from "../components/mainSection/mainSection"
import SecondarySection from "../components/secondarySection/secondarySection"
import PageSection from "../components/pageSection/pageSection"
import Card from "../components/card/card"
import LaunchForm from "../components/launchForm/launchForm"
import OptOut from "../components/optOut/optOut"
import IFrame from "../components/iFrame/iFrame"
import Modal from "../components/modal/modal"

import * as ACTIONS from "../state/actions"

const tcPdf = <IFrame file="../TC.pdf" title="Términos de uso"/>;
const pcPdf = <IFrame file="../PC.pdf" title="Política de privacidad"/>;
const footerDesc = "";

const Wallet = () => {
  const appState = useSelector(state => state.appReducer);
  const dispatch = useDispatch();
  const [cookies, setCookie] = useCookies(['consent']);

  const launchFormHandler = () => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });

    const modal = {
      display: true,
      content: <LaunchForm lang={appState.lang} title="¡Gracias por tu interés!" subtitle="Por ahora el acceso a la plataforma es sólo por invitación. Regístrate y solicita tu acceso cuanto antes." amplitude={appState.amplitude} amplitudeClickEvent="ClickPreSignUpEVVAWallet"/>
    };

    dispatch(ACTIONS.showModal(modal))
  };

  const termsUseHandler = () => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    dispatch(ACTIONS.showModal({display: true, content: tcPdf }))
  };

  const privacyPolicyHandler = () => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    dispatch(ACTIONS.showModal({display: true, content: pcPdf }))
  };

  const cookieHandler = () => {
    setCookie("consent", "dismiss", { path: '/' });
  };

  const closeModal = () => dispatch(ACTIONS.hideModal());

  return (
    <Layout lang={appState.lang} footerDesc={footerDesc} privacyPolicyAction={privacyPolicyHandler} termsUseHandlerAction={termsUseHandler}>
      <SEO lang={appState.lang} page="wallet" title="With EVVA wallet you generate more clients and more sales for your business" description="With EVVA wallet you can generate digital loyalty campaigns, more sales for your business and savings for your clients."/>
      {appState.modal.display && <Modal content={appState.modal.content} closeModal={closeModal}/>}
      {!cookies.consent &&
      <OptOut text="Utilizamos cookies para garantizar que tengas la mejor experiencia en nuestro sitio web." privacyPolicyAction={privacyPolicyHandler} privacyPolicyLabel="Ver Política de privacidad" cookieAction={cookieHandler}/>
      }
      <MainSection title="Ahorra. Recompensa. Crece." description="<h3>Incrementa tus ventas y clientes frecuentes a través de EVVA wallet.</h3>" position="left" image="hero-wallet.png" actionLabel="Únete ahora" action={launchFormHandler} amplitude={appState.amplitude} amplitudeClickEvent="ClickPreSignUpEVVAWallet">
        <AppBar pathname={appState.path}/>
      </MainSection>
      <PageSection maxColumns="6" title="Campañas de lealtad digitales para tu negocio" >
        <Card imageHeight="100px" image="digital-fit-business-icon.svg" description="<p>Para cualquier negocio</p>" textAlign="center" />
        <Card imageHeight="100px" image="digital-branded-loyalty-card-icon.svg" description="<p>Promueve tu marca</p>" textAlign="center" />
        <Card imageHeight="100px" image="digital-coupons-icon.svg" description="<p>Cupones y tarjetas digitales</p>" textAlign="center" />
        <Card imageHeight="100px" image="digital-direct-marketing-icon.svg" description="<p>Interactua con tus clientes</p>" textAlign="center" />
        <Card imageHeight="100px" image="digital-crm-icon.svg" description="<p>Seguimiento a tus clientes</p>" textAlign="center" />
        <Card imageHeight="100px" image="digital-bank-account-icon.svg" description="<p>Crece tus ventas</p>" textAlign="center" />
      </PageSection>
      <PageSection maxColumns="4" title="¿Por qué es tan complicado usar los programas de lealtad tradicionales?" bgColor="#F9F9F9" >
        <Card imageHeight="160px" imageWidth="240px" image="beaurocracy-icon.png" description="<p>Son muy caros, pensados para empresas grandes</p>" textAlign="center" descriptionColor="#363636" />
        <Card imageHeight="160px" imageWidth="240px" image="low-visibility-icon.png" description="<p>Son complicados, se requiere personal experto</p>" textAlign="center" descriptionColor="#363636" />
        <Card imageHeight="160px" imageWidth="240px" image="reach-existing-customer-icon.png" description="<p>No es fácil retener a clientes con las mismas estrategias</p>" textAlign="center" descriptionColor="#363636" />
        <Card imageHeight="160px" imageWidth="240px" image="difficult-find-wallet-icon.png" description="<p>Por lo general son en papel, a nadie le importa</p>" textAlign="center" descriptionColor="#363636"/>
      </PageSection>
      <PageSection maxColumns="2" title="Lealtad digital con EVVA wallet" description="Genera nuevos clientes y recompensa a tus clientes frecuentes, además les ayudas a ahorrar creando lealtad para tu negocio.">
        <Card subtitle="Restaurantes" description="<p>Haz que tu restaurante sea la opción de preferencia para tu clientes. Por ejemplo, ofrece promociones digitales 50% de descuento después de 10 visitas</p>" descriptionColor="#363636" image="burger-icon.png" horizontal padding shadow/>
        <Card subtitle="GYMs" description="<p>Incentiva tus clientes a que hagan más ejercicio! Crea una tarjeta de lealtad para tu estudio que te ayudará a interactuar con tus clientes</p>" descriptionColor="#363636" image="yoga-icon.png" horizontal padding shadow/>
        <Card subtitle="Cafés" description="<p>Atrae clientes a tu cafetería. Inspira lealtad en ellos, por ejemplo ofrece una recompensa VIP para los clientes que compren 10 cafés</p>" descriptionColor="#363636" image="smoothie-icon.png" horizontal padding shadow/>
        <Card subtitle="Tiendas" description="<p>Genera cupones descuentos, genera tu propia tarjeta de lealtad para tu negocios y envía notificaciones a tus clientes frecuentes</p>" descriptionColor="#363636" image="pop-store-icon.png" horizontal padding shadow/>
        <Card subtitle="Salones de belleza" description="<p>Envíe consejos y actualizaciones para el cuidado del cabello a través. Ofrece un corte de cabello o tratamiento de cabello gratis después de 8 visitas</p>" descriptionColor="#363636" image="scissors-icon.png" horizontal padding shadow/>
        <Card subtitle="Clínicas" description="<p>Salud y belleza, a quién no le hace falta? genera nuevos clientes, retiene a tus clientes actuales a través de una tarjeta de lealtad digitales</p>" descriptionColor="#363636" image="massage-icon.png" horizontal padding shadow/>
      </PageSection>
      <SecondarySection
        title="Juntos crecemos"
        shortDescription="De emprendedores para emprendedores. Somos gente como tú que entiende tus necesidades. Nuestro objetivo final es que te enfoques en lo que realmente importa: tu empresa."
        position="center"
        image="evva-together.jpg"
        actionLabel="Regístrate, es gratis"
        action={launchFormHandler}
        amplitude={appState.amplitude}
        amplitudeClickEvent="ClickPreSignUpEVVAWallet"
      />
    </Layout>
  )
};

export default Wallet